import { Asset } from './asset';
import { Entity } from './entity';
import { timestampToDateStr } from '../utils/date';

export enum TxStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
  PENDING = 'pending',
  REVERT = 'revert',
}

export class Tx extends Entity<Tx> {
  id = '';
  chain = '';
  hash = '';
  blockHeight = 0;
  blockHash = '';
  blocktime = 0;
  from = '';
  to = '';
  asset = Asset.from({});
  amount = '';
  fee?: number;
  time?: number;
  memo? = '';
  status = TxStatus.SUCCESS;
  type = '';
  explorerUrl = '';

  constructor(obj: any) {
    super();
    Object.keys(obj).forEach((key) => {
      const val = obj[key];
      switch (key) {
        case 'blocktime':
          this.blocktime = val < 1000000000000 ? val * 1000 : val;
          break;
        case 'time':
          this.time = obj.time
            ? obj.time < 1000000000000
              ? obj.time * 1000
              : obj.time
            : 0;
          break;
        case 'asset':
          this.asset = Asset.from(val);
          break;
        default:
          (this as any)[key] = val;
      }
    });
  }

  clone(patch?: Partial<Tx>): Tx {
    return new Tx({ ...this, ...patch });
  }

  req(): Tx {
    this.blocktime = Math.round(this.blocktime / 1000);
    if (this.time) this.time = Math.round(this.time / 1000);
    return this;
  }
}

export const getTxTime = (tx: Tx) =>
  tx.time ? timestampToDateStr(tx.time) : undefined;

export class TxList extends Entity<TxList> {
  txs: Tx[] = [];
  total = 0;

  constructor(obj: any) {
    super();
    this.txs = obj.txs?.map((tx: any) => Tx.from(tx)) ?? [];
    this.total = obj.total;
  }

  clone(patch?: Partial<TxList>): TxList {
    return new TxList({ ...this, ...patch });
  }

  req(): TxList {
    return this;
  }
}
