import React from 'react';

import EigenLayerLogo from '../../assets/logo/eigenlayer.png';
import StakingRewardsLogo from '../../assets/logo/stakingrewards.png';
import CoinListLogo from '../../assets/logo/coinlist.png';
import HeliusLogo from '../../assets/logo/helius.png';
import AllbridgeLogo from '../../assets/logo/allbridge.png';
import AcrossLogo from '../../assets/logo/across.png';
import LidoLogo from '../../assets/logo/lido.png';
import { ImgProps } from '../display/Img';
import { RoundImg } from '../display/RoundImg';
import { Svg, SvgIconProps } from './common';

export const AcrossIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={AcrossLogo} />
);

export const EigenLayerIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={EigenLayerLogo} />
);

export const LidoIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={LidoLogo} />
);

export const StakingRewardsIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={StakingRewardsLogo} />
);

export const CoinListIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={CoinListLogo} />
);

export const HeliusIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={HeliusLogo} />
);

export const AllbridgeIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={AllbridgeLogo} />
);

export const CryptoRankIcon: React.FC<SvgIconProps> = (props) => (
  <Svg viewBox="0 0 240 240" {...props}>
    <div
      id="in-page-channel-node-id"
      data-channel-name="in_page_channel_RwaIq1"
    />
    <rect width="240" height="240" rx="120" fill="#0587FF" />
    <path
      d="M112.616 152.838C106.688 150.565 100.57 147.154 96.2031 142.096C86.9887 131.166 86.151 119.395 86.151 114.35C86.151 89.1268 103.742 74.8335 123.846 74.8335C133.898 74.8335 143.951 78.1966 150.652 85.7636C152.642 87.7615 154.242 89.8379 155.535 91.9503C157.105 91.3509 158.674 90.7523 160.244 90.1536C166.768 87.6652 173.292 85.1768 179.817 82.6375C168.466 62.0296 146.156 51.2915 123.846 51.2915C92.0147 51.2915 60.1831 73.1519 60.1831 114.35C60.1831 154.879 90.9878 176.693 122.293 177.392C127.787 172.718 134.023 166.355 138.674 161.61C139.868 160.391 140.958 159.278 141.904 158.329C131.114 158.329 120.324 156.499 112.616 152.838Z"
      fill="white"
    />
    <path
      d="M142.958 188.708L137.866 183.716L159.933 159.585L136.168 137.118L141.261 132.125L170.118 159.585L142.958 188.708Z"
      fill="white"
      stroke="white"
      strokeWidth="11.3167"
      strokeMiterlimit="10"
    />
  </Svg>
);
