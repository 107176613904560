import React, { useEffect, useMemo, useState } from 'react';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {
  ColDef,
  GridApi,
  GridOptions,
  ICellRendererComp,
} from 'ag-grid-community';

import { ImageTextCell } from './cells/ImageTextCell';
import { AmountCell } from './cells/AmountCell';
import { ValueChangeCell } from './cells/ValueChangeCell';
import { IconCell } from './cells/IconCell';
import { Text } from '../display/Text';
import { Input } from '../controls/Input';
import { FlexBox } from '../containers/FlexBox';
import { Card } from '../cards/Card';
import { ElementProps, withProps } from '../../entity/components';
import { usePage } from '../../contexts/PageContext';
import { Skeleton } from '../display/Skeleton';

export type BaseTableProps = ElementProps & {
  title?: string;
  Icon?: any;
  roundIcon?: boolean;
  titleInFilters?: boolean;
  hideSearch?: boolean;
  hideFilters?: boolean;
  hideExternalFilters?: boolean;
  hideTitle?: boolean;
  withPagination?: boolean;
  perPage?: number;
  limit?: number;
  card?: boolean;
};

export type TableProps = BaseTableProps & {
  rowData: any[];
  columnDefs: ColDef[];

  searchFields?: string[];
  externalFilterComponents?: any[];
  filterDefs?: any[];
  filterComponents?: any[];
  filterValues?: { [key: string]: any };

  components?: {
    [key: string]: any | ICellRendererComp;
  };
  defaultColDef?: ColDef;
  gridOptions?: GridOptions;
};

const BaseTable = ({
  title,
  hideTitle,
  perPage,
  Icon,
  limit,
  roundIcon,
  withPagination = true,
  rowData,
  columnDefs = [],
  card = true,

  searchFields,
  externalFilterComponents,
  filterDefs,
  filterValues,
  filterComponents,
  titleInFilters,
  hideSearch,
  hideFilters,
  hideExternalFilters,

  defaultColDef = {},
  gridOptions = {},
  components = {},
  ...props
}: TableProps) => {
  const { isMobile } = usePage();
  const [height, setHeight] = useState<number>(0);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [search, setSearch] = useState('');
  const [externalFilters, setExternalFilters] = useState<any[]>([]);
  const [filteredRowData, setFilteredRowData] = useState<any[]>(rowData);

  useEffect(() => applyFilters(), [search, filterValues, rowData]);
  useEffect(
    () => filterDefs && setExternalFilters([...filterDefs]),
    [filterDefs]
  );

  const { onGridReady: externalOnGridReady, ...restGridOptions } =
    gridOptions ?? {};
  const onGridReady = (params: any) => {
    setGridApi(params.api);
    if (externalOnGridReady) externalOnGridReady(params);
  };

  const agComponents = {
    imageText: ImageTextCell,
    icon: IconCell,
    amount: AmountCell,
    valueChange: ValueChangeCell,
    ...components,
  };

  const colDef: ColDef = {
    sortable: true, // Allow sorting on all columns
    filter: false, // Allow filtering on all columns
    resizable: true, // Allow resizing columns
    flex: 1, // Flex layout to make columns auto-size
    cellRenderer: ({ value }: any) => <Text variant="cell">{value}</Text>,
    ...defaultColDef,
  };

  const colDefs = useMemo(
    () =>
      columnDefs.map((c) => ({
        ...c,
      })),
    [columnDefs]
  );

  const agGridOptions: GridOptions = {
    cellSelection: true, // Enable range selection (Enterprise feature)
    rowGroupPanelShow: 'always', // Show row grouping panel (Enterprise feature)
    animateRows: true, // Animate rows
    pagination: withPagination, // Enable pagination
    paginationPageSize: perPage ?? 20, // Rows per page
    autoSizeStrategy: { type: 'fitGridWidth' },
    ...restGridOptions,
  };

  const applyFilters = () => {
    if (!searchFields?.length && !externalFilters?.length) {
      setFilteredRowData(rowData);
      return;
    }

    let newFilteredData = rowData;

    // Apply search filter with OR condition across specified searchFields
    if (!hideSearch && search && !!searchFields?.length) {
      newFilteredData = rowData.filter((row) =>
        searchFields.some((field) => {
          const value = row[field]?.toString().toLowerCase();
          return value && value.includes(search.toLowerCase());
        })
      );
    }

    // Apply external filters if available
    if (externalFilters?.length) {
      externalFilters.forEach((f) => {
        const val = filterValues![f.colId];
        if (val) {
          newFilteredData = newFilteredData.filter((row) => {
            // Convert row value to string for comparison
            const rowValue = String(row[f.colId]);
            // Check if the row value is included in the filter values
            return val.includes(rowValue);
          });
        }
      });
    }

    setFilteredRowData(newFilteredData);
  };

  const handleSearchChange = (value: string) => {
    setSearch(value);
  };

  const rows = !limit
    ? filteredRowData
    : filteredRowData?.slice(
        0,
        limit >= filteredRowData.length - 1 ? filteredRowData.length - 1 : limit
      );

  const showSearch = !hideSearch && searchFields;
  const showFilters = !hideFilters && !!filterComponents;
  const showExternalFilters =
    !hideExternalFilters && !!externalFilterComponents;
  const enabledExternalFilters =
    titleInFilters ||
    (!hideSearch && (searchFields?.length ?? 0) > 0) ||
    (!hideFilters && filterComponents) ||
    (!hideExternalFilters && externalFilterComponents);

  const Container: React.ElementType = card ? Card : FlexBox;

  const TitleIcon = Icon ? (
    <Icon
      round={roundIcon}
      mt="-10px"
      imgSize={props.imgSize ?? 'sm'}
      p={0}
      mr={1}
    />
  ) : null;

  const Title =
    !hideTitle && title ? (
      <FlexBox
        mr={titleInFilters ? 2 : 0}
        alignItems="center"
        mb={titleInFilters ? -2 : 2}
      >
        {TitleIcon}
        <Text mb={1} variant="subtitle2" bold>
          {title}
        </Text>
      </FlexBox>
    ) : null;

  const sx = {
    width: '100%',
    p: props.p ?? 2,
    overflowX: 'scroll',
    borderRadius: '4px',
    ...props.sx,
  };

  const mlFilters = titleInFilters || showSearch ? 2 : 'auto';

  return (
    <Container
      flex
      className={`ag-theme-alpine${enabledExternalFilters ? ' ag-external-filters' : ''}`}
      column
      {...withProps({ ...props, sx })}
    >
      <Skeleton fullHeight delay={200} wait={rows} h="1200px">
        <FlexBox column>
          {!titleInFilters && Title}
          {enabledExternalFilters && (
            <FlexBox
              p={1}
              center
              justifyContent="space-between"
              sx={{
                backgroundColor: '#f8f8f8',
                border: '1px solid #babfc7',
                borderBottom: 'none',
                borderTopLeftRadius: 2,
                borderTopRightRadius: 2,
              }}
            >
              {titleInFilters && Title}
              {showSearch && (
                <Input
                  fullWidth
                  placeholder="Search..."
                  value={search}
                  onChange={(e) => handleSearchChange(e.target.value)}
                />
              )}
              {showFilters && (
                <FlexBox p={0} ml={mlFilters}>
                  {filterComponents}
                </FlexBox>
              )}
              {showExternalFilters && (
                <FlexBox p={0} pt={1} ml={mlFilters}>
                  {externalFilterComponents}
                </FlexBox>
              )}
            </FlexBox>
          )}
        </FlexBox>
        <FlexBox w="100%" sx={{ overflowX: 'scroll' }}>
          <AgGridReact
            rowData={rows}
            columnDefs={colDefs}
            components={agComponents}
            defaultColDef={colDef}
            cellSelection={true}
            rowGroupPanelShow="always"
            animateRows={true}
            paginationPageSizeSelector={!isMobile}
            paginateChildRows={!isMobile}
            {...agGridOptions}
            domLayout="autoHeight"
            onGridReady={onGridReady}
            containerStyle={{ height: height - 170, width: '100%' }}
          />
        </FlexBox>
      </Skeleton>
    </Container>
  );
};

export default BaseTable;
