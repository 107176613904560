import React from 'react';

import ArkhamLogo from '../../assets/logo/arkham.png';
import ChainEyeLogo from '../../assets/logo/chaineye.png';
import CieloLogo from '../../assets/logo/cielo.png';
import CoinGeckoLogo from '../../assets/logo/coingecko.png';
import CoinToolLogo from '../../assets/logo/cointool.png';
import CompoundLogo from '../../assets/logo/compound.png';
import DexScreenerLogo from '../../assets/logo/dexscreener.png';
import ExchangeLogo from '../../assets/logo/exchange.png';
import LunarCrushLogo from '../../assets/logo/lunarcrush.png';
import MakerDAOLogo from '../../assets/logo/makerdao.png';
import SorellaLogo from '../../assets/logo/sorella.png';
import IncryptedLogo from '../../assets/logo/incrypted.png';
import ChainListLogo from '../../assets/logo/chainlist.png';
import LiquidationsLogo from '../../assets/logo/liquidations.png';
import DexToolsLogo from '../../assets/logo/dextools.png';
import DeFiTrackerLogo from '../../assets/logo/defitracker.gif';
import { ImgProps } from '../display/Img';
import { RoundImg } from '../display/RoundImg';
import { Svg, SvgIconProps } from './common';

export const DeFiTrackerIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={DeFiTrackerLogo} />
);

export const ArkhamIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={ArkhamLogo} />
);

export const ChainEyeIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={ChainEyeLogo} />
);

export const DexToolsIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={DexToolsLogo} />
);

export const CieloIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={CieloLogo} />
);

export const CoinGeckoIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={CoinGeckoLogo} />
);

export const CoinToolIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={CoinToolLogo} />
);

export const CompoundIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={CompoundLogo} />
);

export const DexScreenerIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={DexScreenerLogo} />
);

export const ExchangeIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={ExchangeLogo} />
);

export const LunarCrushIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={LunarCrushLogo} />
);

export const MakerDAOIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={MakerDAOLogo} />
);

export const SorellaIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={SorellaLogo} />
);

export const IncryptedIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={IncryptedLogo} />
);

export const ChainListIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={ChainListLogo} />
);

export const LiquidationsIcon = (props: Omit<ImgProps, 'src'>) => (
  <RoundImg {...props} src={LiquidationsLogo} />
);

export const CoinMarketCapIcon: React.FC<SvgIconProps> = (props: any) => (
  <Svg fill={true} {...props}>
    <path d="M20.738 14.341c-.419.265-.912.298-1.286.087-.476-.27-.738-.898-.738-1.774v-2.618c0-1.264-.5-2.164-1.336-2.407-1.416-.413-2.482 1.32-2.882 1.972l-2.498 4.05v-4.95c-.028-1.14-.398-1.821-1.1-2.027-.466-.135-1.161-.081-1.837.953l-5.597 8.987A9.875 9.875 0 012.326 12c0-5.414 4.339-9.818 9.672-9.818 5.332 0 9.67 4.404 9.67 9.818.004.018.002.034.003.053.05 1.049-.29 1.883-.933 2.29zm3.08-2.34l-.001-.055C23.787 5.353 18.497 0 11.997 0 5.48 0 .177 5.383.177 12c0 6.616 5.303 12 11.82 12 2.991 0 5.846-1.137 8.037-3.2.435-.41.46-1.1.057-1.541a1.064 1.064 0 00-1.519-.059 9.56 9.56 0 01-6.574 2.618c-2.856 0-5.425-1.263-7.197-3.268l5.048-8.105v3.737c0 1.794.696 2.374 1.28 2.544.584.17 1.476.054 2.413-1.468.998-1.614 2.025-3.297 3.023-4.88v2.276c0 1.678.672 3.02 1.843 3.68 1.056.597 2.384.543 3.465-.14 1.312-.828 2.018-2.354 1.944-4.193z" />
  </Svg>
);

export const AAVEIcon: React.FC<SvgIconProps> = (props) => (
  <Svg viewBox="0 0 254 254" {...props}>
    <div
      id="in-page-channel-node-id"
      data-channel-name="in_page_channel_1T0Nw6"
    />
    <path
      d="M97.4207 191.145C111.504 188.859 121.068 175.588 118.782 161.505C116.496 147.421 103.226 137.857 89.142 140.143C75.0583 142.429 65.4945 155.7 67.7806 169.783C70.0667 183.867 83.337 193.431 97.4207 191.145Z"
      fill="#201D1D"
    />
    <path
      d="M164.945 191.145C179.029 188.859 188.593 175.588 186.307 161.505C184.021 147.421 170.75 137.857 156.667 140.143C142.583 142.429 133.019 155.7 135.305 169.783C137.591 183.867 150.862 193.431 164.945 191.145Z"
      fill="#201D1D"
    />
    <path
      d="M127 59C56.8532 59 -0.018357 116.955 4.44486e-06 188.424H32.4447C32.4447 134.863 74.4434 91.4385 127 91.4385C179.557 91.4385 221.555 134.863 221.555 188.424H254C254.012 116.955 197.141 59 127 59Z"
      fill="#201D1D"
    />
  </Svg>
);

export const DefiLlamaIcon: React.FC<SvgIconProps> = (props) => (
  <Svg viewBox="0 0 150 150" {...props}>
    <div
      id="in-page-channel-node-id"
      data-channel-name="in_page_channel__vpRyB"
    />
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="43.22"
        y1="2.25"
        x2="40.86"
        y2="21.54"
        gradientTransform="translate(0 149.49) scale(1 -1)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".29" stopColor="#3a8bff" />
        <stop offset="1" stopColor="#81b5ff" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="73.68"
        y1="149.3"
        x2="27.14"
        y2="29.4"
        gradientTransform="translate(0 149.49) scale(1 -1)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".29" stopColor="#0062ee" />
        <stop offset="1" stopColor="#00398a" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-3"
        x1="97.26"
        y1="11.64"
        x2="113.03"
        y2="101.74"
        xlinkHref="#linear-gradient-2"
      />
      <linearGradient
        id="linear-gradient-4"
        x1="78.47"
        y1="-18.83"
        x2="78.47"
        y2="64.85"
        gradientTransform="translate(0 149.49) scale(1 -1)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".29" stopColor="#b3d2ff" />
        <stop offset="1" stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-5"
        x1="56.03"
        y1="109.89"
        x2="26.9"
        y2="127.99"
        xlinkHref="#linear-gradient"
      />
    </defs>
    <path className="cls-7" d="M0,0H150V150H0V0Z" />
    <path
      className="cls-4"
      d="M39.14,78.79c-.9,.64-2.05,.82-3.09,.46l-3.04-1.04c-1.21-.41-1.86-1.73-1.45-2.95,.23-.68,.77-1.22,1.45-1.45l3.04-1.04c1.79-.61,3.73,.34,4.34,2.13,.49,1.43-.02,3.01-1.25,3.89"
    />
    <path
      className="cls-2"
      d="M38.47,104.26c-.49-.85-.26-1.93,.53-2.5l7.08-5.1c1.25-.9,3-.62,3.91,.63,.06,.08,.11,.16,.15,.24,.77,1.33,.32,3.04-1.01,3.81,0,0,0,0-.01,0l-.26,.13-7.95,3.58c-.89,.4-1.95,.06-2.44-.79"
    />
    <path
      className="cls-2"
      d="M50.14,55.47h0c-.4,.68-1.07,1.16-1.83,1.33-.77,.17-1.58,0-2.22-.46l-7.08-5.11c-.85-.62-1.04-1.81-.42-2.67,.53-.73,1.51-.99,2.33-.62l7.95,3.57c1.41,.63,2.04,2.27,1.42,3.68,0,0,0,0,0,0,0,.09-.09,.17-.13,.26"
    />
    <path
      className="cls-9"
      d="M57.3,114.86l-.8,23.79h-22.34c-6.25,0-11.31-5.07-11.32-11.32h0v-5.06c11.88,.02,23.64-2.51,34.46-7.41"
    />
    <path
      className="cls-2"
      d="M117.47,104.26c-.49,.85-1.54,1.19-2.44,.79l-7.95-3.58c-1.4-.62-2.04-2.26-1.41-3.67l.14-.28c.76-1.33,2.46-1.8,3.79-1.04,0,0,.02,.01,.03,.02l.24,.15,7.08,5.1c.79,.57,1.02,1.65,.53,2.5"
    />
    <path
      className="cls-2"
      d="M116.93,51.24l-7.08,5.11c-1.26,.9-3.02,.6-3.92-.66l-.13-.21h0c-.79-1.31-.37-3.02,.95-3.81,0,0,0,0,0,0,.09-.06,.18-.11,.28-.14l7.95-3.57c.96-.43,2.09,0,2.53,.95,.37,.83,.12,1.8-.62,2.33"
    />
    <path
      className="cls-3"
      d="M125.46,76.01c0,.99-.63,1.88-1.57,2.2l-3.05,1.04c-1.8,.59-3.72-.4-4.31-2.19-.58-1.76,.36-3.67,2.11-4.28,.71-.24,1.48-.24,2.2,0l3.05,1.04c.94,.32,1.57,1.2,1.57,2.2"
    />
    <path
      className="cls-1"
      d="M50.37,53.23c.23,.75,.15,1.57-.24,2.25h0c-.4,.68-1.07,1.16-1.83,1.33-.77,.17-1.58,0-2.22-.46l-7.08-5.11c-.85-.62-1.04-1.81-.42-2.67,.53-.73,1.51-.99,2.33-.62l7.95,3.57c.72,.33,1.27,.95,1.51,1.7m0,46.56c-.24,.75-.8,1.37-1.52,1.69l-7.95,3.58c-.95,.46-2.09,.05-2.55-.9-.41-.85-.14-1.86,.64-2.4l7.08-5.1c1.25-.9,3-.62,3.91,.63,.06,.08,.11,.16,.15,.24,.39,.69,.47,1.5,.24,2.25m-14.32-20.53l-3.04-1.04c-1.21-.41-1.86-1.73-1.45-2.95,.23-.68,.77-1.22,1.45-1.45l3.04-1.04c1.78-.63,3.73,.31,4.36,2.09,.63,1.78-.31,3.73-2.09,4.36-.03,0-.05,.02-.08,.03-.71,.24-1.48,.24-2.2,0M71.27,13.12h-17.55c0,17.05-13.83,30.88-30.88,30.89V122.27c11.88,.02,23.64-2.51,34.46-7.41l1.37-40.45c-2.42-1.19-4.14-4.36-4.14-8.07,0-4.17,2.18-7.64,5.05-8.42,.39-2.51,1.28-4.92,2.62-7.09-1.27-.15-2.4-.89-3.07-1.99-2.6-3.82-6.1-7.73-8.79-14.07-.5-1.11-.36-2.41,.36-3.39,.56-.73,1.61-.87,2.34-.32,.07,.06,.14,.12,.21,.19,4.15,3.93,9.15,5.11,11.91,8.88,.2-.47,.47-.91,.79-1.3,1.61-2.08,4.41-2.82,6.84-1.8,.93-3.13,4.22-4.92,7.35-3.99,1.92,.57,3.42,2.07,3.99,3.99,1.25-.56,2.67-.63,3.97-.19,3.2-6.09,7.14-11.76,11.75-16.87-8.84-4.53-18.64-6.88-28.57-6.87"
    />
    <path
      className="cls-3"
      d="M39.14,78.79c-.9,.64-2.05,.82-3.09,.46l-3.04-1.04c-1.21-.41-1.86-1.73-1.45-2.95,.23-.68,.77-1.22,1.45-1.45l3.04-1.04c1.79-.61,3.73,.34,4.34,2.13,.49,1.43-.02,3.01-1.25,3.89"
    />
    <path
      className="cls-8"
      d="M123.89,78.21l-3.05,1.04c-1.8,.59-3.72-.4-4.31-2.19-.58-1.76,.36-3.67,2.11-4.28,.71-.24,1.48-.24,2.2,0l3.05,1.04c1.21,.41,1.86,1.73,1.45,2.95-.23,.68-.77,1.22-1.45,1.45m-6.43,26.05c-.49,.85-1.54,1.19-2.44,.79l-7.95-3.58c-1.4-.62-2.04-2.26-1.41-3.67l.14-.28c.76-1.33,2.46-1.8,3.79-1.04,0,0,.02,.01,.03,.02l.24,.15,7.08,5.1c.79,.57,1.02,1.65,.53,2.5m-11.92-51.03c.24-.75,.8-1.37,1.52-1.69l7.95-3.57c.96-.43,2.09,0,2.53,.95,.37,.83,.12,1.8-.62,2.33l-7.08,5.11c-1.26,.9-3.02,.6-3.92-.66l-.13-.21h0c-.4-.68-.5-1.51-.26-2.26m-.32-30.12c-1.75-1.13-3.54-2.17-5.4-3.12-4.6,5.11-8.55,10.78-11.75,16.87,1.13,.36,2.13,1.05,2.86,1.99,.32,.4,.58,.84,.79,1.3,2.59-3.54,7.14-4.34,11.13-8.15,.8-.83,2.08-.96,3.03-.3,.21,.14,.39,.31,.53,.52,.53,.68,.64,1.6,.28,2.39l-.11,.27c-2.67,6.21-6.16,10.16-8.74,13.97-.67,1.11-1.82,1.84-3.11,1.99,1.32,2.16,2.2,4.57,2.57,7.09,2.59,.71,4.6,3.57,4.98,7.18,.05,.41,.07,.83,.07,1.24,0,3.49-1.53,6.5-3.73,7.83-.13,.09-.27,.17-.41,.24v.6l1.91,56.59c20.8-10.79,33.85-32.28,33.85-55.71h0c.02-21.34-10.81-41.22-28.74-52.78"
    />
    <path
      className="cls-10"
      d="M90.38,72.33c.7,1.26,.62,2.8-.21,3.98-2.72,3.97-6.53,6.99-11.38,6.99h-1.03c-4.19-.36-8.59-3.1-11.32-6.4-1.01-1.23-1.15-2.95-.35-4.33,1.07-1.78,1.63-3.82,1.63-5.89,0-1.95-.48-3.87-1.39-5.59-.69-1.25-.6-2.78,.21-3.94,.21,.28,.45,.53,.72,.75,1.98,1.55,4.75,1.59,6.77,.1,0,2.17,2.19,3.92,4.87,3.92,.24,.02,.49,.02,.74,0,2.35-.29,4.14-1.91,4.14-3.88,2.05,1.53,4.88,1.48,6.87-.14l.27-.24c.36,1.07,.23,2.24-.35,3.2-1.06,1.77-1.61,3.79-1.6,5.85,0,1.97,.49,3.9,1.43,5.63m16.03-40.13c-.14-.2-.32-.38-.53-.52-.94-.64-2.2-.51-2.99,.3-3.99,3.82-8.59,4.6-11.13,8.15-.21-.46-.48-.9-.79-1.3-.74-.93-1.73-1.62-2.86-1.99-1.3-.44-2.72-.37-3.97,.19-.93-3.13-4.22-4.92-7.35-3.99-1.92,.57-3.42,2.07-3.99,3.99-2.42-1.02-5.23-.29-6.84,1.8-.33,.39-.59,.83-.79,1.3-2.76-3.77-7.75-4.95-11.91-8.88-.61-.69-1.66-.75-2.35-.14,0,0,0,0-.01,.01-.09,.07-.16,.16-.23,.25-.73,.98-.87,2.28-.36,3.39,2.68,6.33,6.19,10.23,8.79,14.07,.67,1.11,1.82,1.84,3.1,1.99-1.34,2.16-2.22,4.57-2.62,7.09-2.86,.78-5.05,4.25-5.05,8.42,0,3.71,1.73,6.88,4.14,8.07l-1.37,40.45-.8,23.79h14.76c9.93,.02,19.73-2.34,28.57-6.87l.33-.18-1.9-56.59v-.6c.14-.07,.28-.15,.41-.24,2.21-1.33,3.73-4.34,3.73-7.83,0-.41-.02-.83-.07-1.24-.38-3.61-2.4-6.47-4.98-7.18-.38-2.52-1.27-4.93-2.62-7.09,1.29-.14,2.44-.88,3.11-1.99,2.58-3.82,6.06-7.76,8.74-13.97l.11-.27c.36-.78,.26-1.71-.28-2.39"
    />
    <path
      className="cls-6"
      d="M53.72,13.12c0,17.05-13.83,30.88-30.88,30.89V24.44c0-6.25,5.07-11.31,11.32-11.32h19.57Z"
    />
    <path
      className="cls-3"
      d="M84.12,67.75l-3.18,2.92c-.27,.26-.47,.59-.56,.95-.21,.84-.62,2.49-.95,4.06-.11,.44-.51,.74-.95,.73h0c-.45,.01-.85-.29-.95-.73l-1.01-4.06c-.09-.37-.29-.7-.56-.95l-3.18-2.92c-.37-.37-.37-.97,0-1.35,.21-.22,.51-.33,.81-.3l4.77,.65h.26l.37-.05,4.4-.6c.4-.04,.78,.17,.95,.53,.18,.38,.09,.83-.22,1.11m4.77-1.07c.02-2.05,.59-4.06,1.66-5.82,.56-.96,.67-2.11,.31-3.16l-.22,.2c-1.99,1.57-4.8,1.59-6.82,.06,0,1.95-1.78,3.59-4.14,3.86-.24,.02-.49,.02-.74,0-2.68,0-4.87-1.76-4.87-3.92-2.02,1.53-4.82,1.51-6.82-.06-.27-.22-.51-.48-.72-.75-.81,1.17-.9,2.7-.21,3.94,.93,1.74,1.41,3.68,1.39,5.65,0,2.05-.54,4.07-1.59,5.83-.79,1.38-.65,3.1,.35,4.33,2.74,3.29,7.12,6.04,11.32,6.4h1.03c4.85,0,8.66-3.02,11.38-6.99,.79-1.17,.85-2.69,.16-3.92-.94-1.73-1.43-3.67-1.43-5.63"
    />
    <path
      className="cls-5"
      d="M84.12,67.75l-3.18,2.92c-.27,.26-.47,.59-.56,.95-.21,.84-.62,2.49-.95,4.06-.11,.44-.51,.74-.95,.73h0c-.45,.01-.85-.29-.95-.73l-1.01-4.06c-.09-.37-.29-.7-.56-.95l-3.18-2.92c-.37-.37-.37-.97,0-1.35,.21-.22,.51-.33,.81-.3l4.77,.65h.26l.37-.05,4.4-.6c.4-.04,.78,.17,.95,.53,.18,.38,.09,.83-.22,1.11"
    />
  </Svg>
);

export const TokenUnlocksIcon: React.FC<SvgIconProps> = (props) => (
  <Svg viewBox="0 0 456 456" {...props}>
    <div
      id="in-page-channel-node-id"
      data-channel-name="in_page_channel_N4sVq1"
    />
    <rect width="456" height="456" fill="#C51052" />
    <path
      d="M168.832 344.648L200.596 290.058C194.659 285.776 189.827 280.16 186.498 273.67C183.169 267.18 181.436 260 181.441 252.718C181.441 227.211 202.286 206.533 228 206.533C253.714 206.533 274.559 227.211 274.559 252.718C274.563 260.027 272.816 267.231 269.463 273.738C266.11 280.246 261.246 285.869 255.272 290.146L287.032 344.72C317.638 325.361 337.938 291.383 337.938 252.704C337.938 192.473 288.716 143.647 228 143.647C167.284 143.647 118.062 192.488 118.062 252.718C118.062 291.344 138.3 325.269 168.832 344.648Z"
      fill="white"
    />
    <path
      d="M228 206.533C234.116 206.517 240.176 207.704 245.827 210.026C251.478 212.348 256.608 215.759 260.922 220.061C265.259 224.339 268.697 229.429 271.038 235.035C273.378 240.64 274.575 246.651 274.559 252.718C274.575 258.786 273.378 264.797 271.038 270.402C268.697 276.008 265.259 281.098 260.922 285.376C256.608 289.678 251.478 293.089 245.827 295.411C240.176 297.733 234.116 298.92 228 298.904C221.883 298.92 215.824 297.733 210.173 295.411C204.522 293.089 199.391 289.678 195.078 285.376C190.741 281.098 187.302 276.008 184.961 270.403C182.621 264.797 181.424 258.786 181.441 252.718C181.424 246.651 182.621 240.64 184.961 235.034C187.302 229.429 190.741 224.339 195.078 220.061C199.391 215.758 204.522 212.348 210.173 210.026C215.824 207.704 221.883 206.517 228 206.533ZM228 143.662C167.282 143.662 118.062 192.488 118.062 252.718C118.062 312.949 167.282 361.775 228 361.775C288.718 361.775 337.938 312.949 337.938 252.718C337.938 192.488 288.716 143.662 228 143.662Z"
      fill="url(#paint0_linear_5328_86061)"
    />
    <path
      d="M191.292 201.002V139.882C191.292 130.238 195.134 121.117 202.107 114.198C209.081 107.278 218.277 103.469 228 103.469C237.721 103.469 246.917 107.28 253.891 114.198C259.246 119.465 262.83 126.246 264.15 133.612C278.296 137.837 291.574 144.516 303.368 153.34V139.881C303.368 98.7656 269.451 65.1176 228 65.1176C186.552 65.1176 152.632 98.7601 152.632 139.881V201.002H191.292Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M225.745 386.402C207.727 386.436 189.887 382.876 173.294 375.933C157.284 369.246 142.739 359.551 130.466 347.384C118.163 335.25 108.36 320.869 101.599 305.041C94.5737 288.638 90.9687 271.001 91.0002 253.188C90.9657 235.375 94.5674 217.738 101.59 201.334C108.35 185.506 118.154 171.126 130.457 158.991C142.73 146.825 157.275 137.129 173.285 130.442C189.881 123.499 207.724 119.938 225.745 119.973C243.763 119.939 261.602 123.5 278.196 130.442C294.206 137.129 308.75 146.825 321.023 158.991C333.33 171.125 343.137 185.505 349.9 201.334C356.922 217.738 360.524 235.375 360.49 253.188C360.524 271 356.922 288.637 349.9 305.041C343.14 320.869 333.336 335.25 321.033 347.384C308.76 359.551 294.215 369.246 278.205 375.933C261.609 382.877 243.766 386.438 225.745 386.402ZM225.745 127.108C208.693 127.074 191.81 130.444 176.107 137.014C160.953 143.342 147.185 152.52 135.569 164.036C123.921 175.52 114.638 189.131 108.237 204.113C101.591 219.638 98.1833 236.33 98.217 253.188C98.1833 270.046 101.591 286.737 108.237 302.262C114.638 317.244 123.921 330.855 135.569 342.339C147.185 353.856 160.953 363.033 176.107 369.361C191.81 375.932 208.693 379.301 225.745 379.268C242.796 379.301 259.68 375.932 275.383 369.361C290.537 363.033 304.304 353.856 315.92 342.339C327.569 330.855 336.852 317.244 343.253 302.262C349.898 286.737 353.306 270.046 353.273 253.188C353.306 236.33 349.898 219.638 343.253 204.113C336.852 189.131 327.569 175.52 315.92 164.036C304.304 152.52 290.537 143.342 275.383 137.014C259.68 130.444 242.796 127.074 225.745 127.108Z"
      fill="url(#paint1_linear_5328_86061)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5328_86061"
        x1="228"
        y1="143.662"
        x2="228.555"
        y2="380.805"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.627693" stopColor="white" stopOpacity="0.48" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5328_86061"
        x1="225.745"
        y1="119.973"
        x2="225.745"
        y2="386.403"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </Svg>
);

export const SoSoValueIcon: React.FC<SvgIconProps> = (props) => (
  <Svg viewBox="0 0 28 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m14.299 31.832 13.224-7.672a.68.68 0 0 0 .336-.584L27.827 8.29a.68.68 0 0 0-.338-.584L14.233.09a.67.67 0 0 0-.674 0L.335 7.762A.67.67 0 0 0 0 8.346l.032 15.288a.67.67 0 0 0 .338.583l13.256 7.615a.67.67 0 0 0 .673 0m3.736-18.211 5.816-3.415a.337.337 0 0 0-.003-.582l-9.608-5.52a.67.67 0 0 0-.673 0L3.813 9.763a.67.67 0 0 0-.336.585l.009 3.232a.67.67 0 0 0 .337.582l6.302 3.623a.67.67 0 0 1 .338.584v3.242a.225.225 0 0 1-.337.195L7.18 20.11a.45.45 0 0 1-.222-.392l-.004-1.063a.68.68 0 0 0-.344-.586l-2.626-1.473a.338.338 0 0 0-.501.294v2.02l.007 2.715a.67.67 0 0 0 .338.582l9.452 5.414a.448.448 0 0 0 .673-.39l-.016-10.896a.67.67 0 0 0-.338-.583L7.33 12.15a.224.224 0 0 1 0-.39l6.249-3.614a.68.68 0 0 1 .674 0l2.827 1.626a.224.224 0 0 1 0 .39l-2.492 1.435a.336.336 0 0 0 .013.59l2.783 1.45a.67.67 0 0 0 .651-.016"
      clipRule="evenodd"
    ></path>
    <path
      fill="var(--accent-600)"
      d="m17.367 18.108.006 3.814a.45.45 0 0 0 .674.388l3.296-1.912a.68.68 0 0 0 .336-.584l-.008-3.811a.45.45 0 0 0-.674-.389l-3.294 1.91a.67.67 0 0 0-.336.584"
    ></path>
  </Svg>
);
